import type { Moment } from 'moment';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { putLock, putUnlock, getUserinfo, login, logout } from '@/api/login';
import { EnumUserRole, EnumUserStatus, EnumRemember } from '@/typings/enum';
import Cookie from '@/utils/cookie';

export interface AuthUserInfo {
  realName: string;
  username: string;
  phone: string;
  role: EnumUserRole[];
  status: EnumUserStatus;
  autoLockTime: number;
  userId: number;
}

interface commonState {
  loading: boolean;
  lock: boolean;
  userinfo: AuthUserInfo;
  token: string;
  expireTime: number;
  alarmVisible: boolean; // header是否显示异常报警
  alarmLocate: AlarmInfo; // 需要定位的异常报警信息
  selectYear: number | undefined; // 选中的年份
  controlTimeRange: [string, string] | null; // 控制时段
}

const initialState: commonState = {
  loading: false,
  lock: false,
  userinfo: {
    realName: '',
    username: '',
    phone: '',
    role: [],
    status: EnumUserStatus.ACTIVE,
    autoLockTime: 0,
    userId: 0,
  },
  token: '',
  expireTime: 0,
  alarmVisible: false,
  alarmLocate: {
    code: '',
    areaId: 0,
  },
  selectYear: undefined,
  controlTimeRange: null,
};

export const commonSlice = createSlice({
  name: 'commone',
  initialState,
  reducers: {
    toggleLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    toggleLock: (state, action: PayloadAction<boolean>) => {
      state.lock = action.payload;
    },
    updateUserinfo: (state, action: PayloadAction<AuthUserInfo>) => {
      state.userinfo = action.payload;
    },
    updateToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    updateAlarmVisible: (state, action: PayloadAction<boolean>) => {
      state.alarmVisible = action.payload;
    },
    updateAlarmLocate: (state, action: PayloadAction<AlarmInfo>) => {
      state.alarmLocate = action.payload;
    },
    updateSelecteYear: (state, action: PayloadAction<number | undefined>) => {
      state.selectYear = action.payload;
    },
    updateControlTimeRange: (state, action: PayloadAction<[string, string] | null>) => {
      state.controlTimeRange = action.payload;
    },
  },
  extraReducers: (builder) => {
    // 锁屏
    builder.addCase(putLock.fulfilled, (state, { payload }) => {
      if (payload.code === 0) {
        state.lock = true;
        // 防止锁屏后，用户通过刷新浏览器的方法跳过解锁步骤，所以将cookie清除，使用户未解锁，刷新页面时跳转登录页
        Cookie.setCookie('token', '');
      }
    });
    // 解锁屏幕
    builder.addCase(putUnlock.fulfilled, (state, { payload }) => {
      if (payload.code === 0 && payload.data.result) {
        Cookie.setCookie('token', state.token, state.expireTime as unknown as string);
        state.lock = false;
      }
    });
    // 解锁屏幕失败，清空cookie
    builder.addCase(putUnlock.rejected, () => {
      Cookie.setCookie('token', '');
    });
    // 获取用户信息
    builder.addCase(getUserinfo.fulfilled, (state, { payload }) => {
      if (payload.code === 0) {
        state.userinfo = payload.data;
      }
    });
    // 登录
    builder.addCase(login.fulfilled, (state, { payload, meta }) => {
      if (payload.code === 0) {
        state.token = payload.data.token;
        const time = meta.arg.remember === EnumRemember.Y ? payload.data.expireTime * 1000 : 0;
        state.expireTime = time;
        Cookie.setCookie('token', payload.data.token, time as unknown as string);
        state.userinfo = payload.data.userinfo;
      }
    });
    // 退出登录
    builder.addCase(logout.fulfilled, (state, { payload }) => {
      if (payload.code === 0) {
        Cookie.setCookie('token', '');
      }
    });
  },
});

export const {
  toggleLoading,
  toggleLock,
  updateUserinfo,
  updateToken,
  updateAlarmLocate,
  updateAlarmVisible,
  updateSelecteYear,
  updateControlTimeRange,
} = commonSlice.actions;
export interface AlarmInfo {
  code: string; // 灯组code
  areaId: number; // 所属区域
}
export default commonSlice.reducer;
