import React from 'react';
import classNames from 'classnames';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import './BdiaButton.less';

interface BdiaButtonProps extends ButtonProps {
  gray?: boolean;
  inputBtn?: boolean;
}

const BdiaButton = ({ type = 'primary', size = 'middle', gray, className, inputBtn, ...props }: BdiaButtonProps) => {
  return (
    <Button
      type={type}
      className={classNames(
        'bdia-button',
        `bdia-${size}-button`,
        { 'bdia-gray-button': gray, 'bdia-input-button': inputBtn },
        className,
      )}
      {...props}
    />
  );
};
export default BdiaButton;
