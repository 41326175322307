import request from './request';
import { UserType } from '@/views/User/User';

// 控灯参数查询
export async function getSysPlanConfig(params: { areaId: number }) {
  return await request('plan/control-config', {
    method: 'GET',
    body: params,
  });
}
export async function updateSysCtrlTime(params: { startTime: string | null; endTime: string | null; areaId: number }) {
  return await request('plan/control-time', {
    method: 'PUT',
    body: params,
  });
}
// 控灯参数修改
export async function updateSysPlanCp(params: { x: number; y: number; z: number; areaId: number }) {
  return await request('plan/control-param', {
    method: 'PUT',
    body: params,
  });
}

// 控灯方案-灯组类别查询
export async function getSysPlanGroupType(params: { areaId: number }) {
  return await request('group/type', {
    method: 'GET',
    body: params,
  });
}
// 控灯方案-方案查询
export async function getSysPlanInfo(params: { typeId: number; areaId: number }) {
  return await request('plan/group/bytype', {
    method: 'GET',
    body: params,
  });
}
// 控灯方案-方案编辑保存
export async function updateSysPlanInfo(params: {
  areaId: number;
  plan: Array<{
    code: string;
    defaultMode: number;
    defaultBrightness: number;
    minBrightness?: number;
    maxBrightness?: number;
  }>;
}) {
  return await request('plan/group/bytype', {
    method: 'PUT',
    body: params,
  });
}

// 底层灯组列表查询
export async function getSysLightGroup(params: { page: number; size: number }) {
  return await request('basegroup/list', {
    method: 'GET',
    body: params,
  });
}
// 获取层间灯的table
export async function getTimeScheduleTable(params: { areaId: number }) {
  return await request('time_schedule/list', {
    method: 'GET',
    body: params,
  });
}
// 未配置的网关查询
export async function getUnconfigGateway(params: { areaId: number }) {
  return await request('time_schedule/unchecked_group', {
    method: 'GET',
    body: params,
  });
}
// 获取层间灯灯组详情
export async function getTimeScheduleGroupDetail(params: { groupId: number }) {
  return await request('time_schedule/group_detail', {
    method: 'GET',
    body: params,
  });
}
// 层间灯-新增灯组
export async function addTimeScheduleGroup(params: {
  groupName: string;
  areaId: number;
  controlArea: string;
  gatewayCodes: string[];
}) {
  return await request('time_schedule/add_group', {
    method: 'POST',
    body: params,
  });
}
// 层间灯-编辑灯组
export async function updateTimeScheduleGroup(params: {
  groupName: string;
  groupId: number;
  areaId: number;
  controlArea: string;
  gatewayCodes: string[];
}) {
  return await request('time_schedule/update_group', {
    method: 'POST',
    body: params,
  });
}
// 层间灯-删除灯组
export async function delTimeScheduleGroup(params: { groupId: number; areaId: number }) {
  return await request('time_schedule/del_group', {
    method: 'DELETE',
    body: params,
  });
}
// 层间灯-编辑灯组计划表
export async function updateTimeSchedule(params: {
  groupId: number;
  schedule: { time: string; brightness: number }[];
}) {
  return await request('time_schedule/update_schedule', {
    method: 'POST',
    body: params,
  });
}
// 人员列表查询
export async function getSysUserList(params: { page: number; size: number }) {
  return await request('user/list', {
    method: 'GET',
    body: params,
  });
}
// 人员新增
export async function addSysUser(params: UserType) {
  return await request('user/create', {
    method: 'POST',
    body: params,
  });
}
// 人员编辑
export async function updateSysUser(params: UserType) {
  return await request('user/edit', {
    method: 'PUT',
    body: params,
  });
}
// 人员密码重置
export async function resetSysUserPwd(userId: number) {
  return await request('user/password/reset', {
    method: 'PUT',
    body: { userId },
  });
}
