import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Footer from '../Footer/Footer';
import './Content.less';

const PageContent = () => {
  const location = useLocation();
  const [footerVisible, setFooterVisible] = useState(false);

  useEffect(() => {
    const { pathname } = location;
    if (pathname === '/select' || pathname === '/user') {
      setFooterVisible(false);
    } else {
      setFooterVisible(true);
    }
  }, [location]);

  return (
    <div className="content">
      <div className="pages" style={{ height: footerVisible ? 'calc(100% - 0.78rem)' : '100%' }}>
        <Outlet />
      </div>
      {footerVisible && <Footer />}
    </div>
  );
};

export default PageContent;
