import { Cascader } from 'antd';
import { BaseOptionType, DefaultOptionType } from 'antd/lib/cascader';
import classNames from 'classnames';
import React from 'react';
import './BdiaCascader.less';
import '../BdiaSelect/BdiaSelect.less';
type CascaderProps = React.ComponentProps<typeof Cascader>;

type ThemeStyle = 'dark' | 'white';

type BdiaCascaderProps = CascaderProps & {
  themeStyle?: ThemeStyle;
  size?: 'large' | 'small' | 'mini';
};

export default function BdiaCascader({ size, themeStyle, className, ...props }: BdiaCascaderProps) {
  return (
    <div
      className={classNames(
        'bdia-select',
        `bdia-select-${themeStyle}`,
        `${size ? `bdia-select-${size}` : ''}`,

        'bdia-cascader',
        `bdia-cascader-${themeStyle}`,
        `${size ? `bdia-select-${size}` : ''}`,
        className,
      )}
    >
      <Cascader
        notFoundContent={<EmptyContent />}
        suffixIcon={<span className={classNames('iconfont', 'icon-zhankaishouqi', 'select-arrow-icon')} />}
        popupClassName="bdia-select-dropdown"
        {...props}
      />
    </div>
  );
}
function EmptyContent() {
  return (
    <div className="select-empty-box">
      <span className="iconfont icon-kongzhuangtai-01" />
      <span className="select-empty-text">无数据</span>
    </div>
  );
}
