import React, { Suspense, useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import Header from './comps/Header/Header';
import BdiaLoading from '@/components/BdiaLoading/BdiaLoading';
import LockDialog from './comps/LockDialog/LockDialog';
import { useAppSelector, useAppDispatch } from '@/store/hooks';
import { updateToken } from '@/store/features/commonSlice';
import { getUserinfo } from '@/api/login';
import { WatchScreenLock } from '@/utils/general';
import Cookie from '@/utils/cookie';
import routes from './routes';
import './Layout.less';

const Layout = () => {
  const routeElement = useRoutes(routes);
  const commonState = useAppSelector((state) => state.common);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const token = Cookie.getCookie('token');
    if (token) {
      dispatch(getUserinfo()).then(({ payload }) => {
        if (payload?.code === 0) {
          dispatch(updateToken(token));
          WatchScreenLock.set(payload.data.autoLockTime);
        }
      });
    }
    document.addEventListener('click', () => {
      window.clickHasOccurred = true;
    });
  }, []);

  return (
    <>
      <div className="layout">
        <Header />
        <div className="main">
          <Suspense fallback={<BdiaLoading />}>{routeElement}</Suspense>
          {commonState.loading && <BdiaLoading />}
        </div>
      </div>
      {commonState.lock && <LockDialog />}
    </>
  );
};
export default Layout;
