import { configureStore } from '@reduxjs/toolkit';
import lampSlice from './features/lampSlice';
import commonSlice from './features/commonSlice';
import acSlice from './features/acSlice';

export const store = configureStore({
  reducer: {
    lamp: lampSlice,
    common: commonSlice,
    ac: acSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
