import classNames from 'classnames';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { getAreaSetting, getSupplyCycle, updateSupplyCycle, SupplyCycleParams } from '@/api/acSystem';
import BdiaDialog from '@/components/BdiaDialog/BdiaDialog';
import TransitionPng from '@/assets/imgs/ac-transition.png';
import SummerPng from '@/assets/imgs/ac-summer.png';
import WinterPng from '@/assets/imgs/ac-winter.png';
import { EnumSeason } from '@/typings/enum';
import './Season.less';
import BdiaInput from '@/components/BdiaInput/BdiaInput';
import BdiaButton from '@/components/BdiaButton/BdiaButton';

const SEASONS: SeasonItem[] = [
  {
    name: '过渡季-春',
    img: TransitionPng,
    key: EnumSeason.SPRING,
    color: '#77EEA7',
    highlightColor: '#C6FADB',
    m: 'springM',
    d: 'springD',
    min: 'springTmpMin',
    max: 'springTmpMax',
  },
  {
    name: '制冷季',
    img: SummerPng,
    key: EnumSeason.SUMMER,
    color: '#64A0C5',
    highlightColor: '#AFDBF6',
    m: 'summerM',
    d: 'summerD',
    min: 'summerTmpMin',
    max: 'summerTmpMax',
  },
  {
    name: '过渡季-秋',
    img: TransitionPng,
    key: EnumSeason.AUTUMN,
    color: '#77EEA7',
    highlightColor: '#C6FADB',
    m: 'autumnM',
    d: 'autumnD',
    min: 'autumnTmpMin',
    max: 'autumnTmpMax',
  },
  {
    name: '供暖季',
    img: WinterPng,
    key: EnumSeason.WINTER,
    color: '#FF9400',
    highlightColor: '#F9DDB6',
    m: 'winterM',
    d: 'winterD',
    min: 'winterTmpMin',
    max: 'winterTmpMax',
  },
];

const DEFAULT_DATE: { [key: string]: [string, boolean] } = {
  springM: ['', false], // [值，校验错误]
  springD: ['', false],
  springTmpMin: ['', false],
  springTmpMax: ['', false],
  summerM: ['', false],
  summerD: ['', false],
  summerTmpMin: ['', false],
  summerTmpMax: ['', false],
  autumnM: ['', false],
  autumnD: ['', false],
  autumnTmpMin: ['', false],
  autumnTmpMax: ['', false],
  winterM: ['', false],
  winterD: ['', false],
  winterTmpMin: ['', false],
  winterTmpMax: ['', false],
};

export default function HeaderSeason({ season, onClose, updateSeason }: SeasonProps) {
  const [currentSeason, setCurrentSeason] = useState<SeasonItem>();
  const [visible, setVisible] = useState<boolean>(false);
  const [cycleDate, setCycleDate] = useState<{ [key: string]: string }>({});
  const [edit, setEdit] = useState<boolean>(false);
  const [date, setDate] = useState<{ [key: string]: [string, boolean] }>(DEFAULT_DATE);
  const [editDate, setEditDate] = useState<{ [key: string]: [string, boolean] }>(DEFAULT_DATE);
  const [saved, setSaved] = useState<boolean>(false);

  useEffect(() => {
    if (visible) {
      getCycleDateil();
    }
  }, [visible]);

  useEffect(() => {
    const cur = SEASONS.find((item) => item.key === season);
    setCurrentSeason(cur);
  }, [season]);

  useEffect(() => {
    if (cycleDate) {
      const newDate = cloneDeep(DEFAULT_DATE);
      Object.keys(cycleDate).forEach((k) => {
        if (k.endsWith('Min') || k.endsWith('Max')) {
          newDate[k][0] = cycleDate[k];
        } else {
          newDate[`${k}M`][0] = cycleDate[k]?.split('-')[0];
          newDate[`${k}D`][0] = cycleDate[k]?.split('-')[1];
        }
      });
      setDate(newDate);
      setEditDate(cloneDeep(newDate));
    }
  }, [cycleDate]);

  const getCycleDateil = () => {
    getSupplyCycle().then((res) => {
      if (res.code === 0) {
        setCycleDate(res.data);
      }
    });
  };

  const handleMonthChange = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    const val = e.target.value;
    editDate[key][0] = val;
    editDate[key][1] = Number(val) < 1 || Number(val) > 12;
    if (!editDate[key][1]) {
      // 月份正常的情况下再校验日期
      const keyD = key.slice(0, -1) + 'D';
      if ([4, 6, 9, 11].indexOf(Number(val)) >= 0 && editDate[keyD][0] === '31') {
        editDate[keyD][1] = true;
      } else if (Number(val) === 2 && Number(editDate[keyD][0]) > 29) {
        editDate[keyD][1] = true;
      }
    }
    setEditDate(cloneDeep(editDate));
  };
  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    const val = e.target.value;
    editDate[key][0] = e.target.value;
    editDate[key][1] = Number(val) < 1 || Number(val) > 31;
    const keyM = key.slice(0, -1) + 'M';
    if ([4, 6, 9, 11].indexOf(Number(editDate[keyM][0])) >= 0 && val === '31') {
      editDate[key][1] = true;
    } else if (Number(editDate[keyM][0]) === 2 && Number(val) > 29) {
      editDate[key][1] = true;
    }
    setEditDate(cloneDeep(editDate));
  };
  const handleMinChange = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    let val = e.target.value;
    const dotInd = val.indexOf('.');
    if (dotInd >= 0) {
      val = val.slice(0, dotInd + 2);
    }
    if (dotInd === 0) {
      val = '0' + val;
    }
    editDate[key][0] = val;
    const num = Number(val);
    const isOut = num < -100 || num > 100; // 超出-100~100
    editDate[key][1] = isOut;
    if (!isOut) {
      const maxKey = key.slice(0, -3) + 'Max';
      const max = Number(editDate[maxKey][0]);
      if (num > max) {
        editDate[key][1] = true;
        editDate[maxKey][1] = true;
      } else {
        editDate[key][1] = false;
        editDate[maxKey][1] = false;
      }
    }
    setEditDate(cloneDeep(editDate));
  };
  const handleMaxChange = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
    let val = e.target.value;
    const dotInd = val.indexOf('.');
    if (dotInd > 0) {
      val = val.slice(0, dotInd + 2);
    } else if (dotInd === 0) {
      val = '0' + val;
    }
    editDate[key][0] = val;
    const num = Number(val);
    const isOut = num < -100 || num > 100; // 超出-100~100
    editDate[key][1] = isOut;
    if (!isOut) {
      const minKey = key.slice(0, -3) + 'Min';
      const min = Number(editDate[minKey][0]);
      if (num < min) {
        editDate[key][1] = true;
        editDate[minKey][1] = true;
      } else {
        editDate[key][1] = false;
        editDate[minKey][1] = false;
      }
    }
    setEditDate(cloneDeep(editDate));
  };

  const formatDate = (val: string) => {
    if (val.length === 1) {
      return `0${val}`;
    }
    return val;
  };

  const handleCancel = () => {
    setEdit(false);
  };

  const handleSave = () => {
    const validate = Object.keys(editDate).every((k) => !editDate[k][1]);
    if (validate) {
      const { springM, springD, summerM, summerD, autumnM, autumnD, winterM, winterD, ...rest } = editDate;
      const temp: { [key: string]: number } = {};
      Object.keys(rest).forEach((k: string) => {
        temp[k] = Number(editDate[k][0]);
      });
      updateSupplyCycle({
        spring: `${formatDate(editDate.springM[0])}-${formatDate(editDate.springD[0])}`,
        summer: `${formatDate(editDate.summerM[0])}-${formatDate(editDate.summerD[0])}`,
        autumn: `${formatDate(editDate.autumnM[0])}-${formatDate(editDate.autumnD[0])}`,
        winter: `${formatDate(editDate.winterM[0])}-${formatDate(editDate.winterD[0])}`,
        ...temp,
      } as unknown as SupplyCycleParams).then((res) => {
        if (res.code === 0) {
          getCycleDateil();
          updateSeason();
          setEdit(false);
          setSaved(true);
        }
      });
    }
  };

  const handleClose = () => {
    setVisible(false);
    if (saved) {
      onClose();
    }
  };

  return (
    <>
      <div
        className="header-season"
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        style={{ color: currentSeason?.color, '--hover-color': currentSeason?.highlightColor } as React.CSSProperties}
        // onClick={() => setVisible(true)}
      >
        <img src={currentSeason?.img} />
        <span>{currentSeason?.name}</span>
      </div>
      {visible && (
        <BdiaDialog style={{ width: '7.52rem', height: '6.76rem' }} className="header-season-dialog">
          <div className="header-season-dialog-title">供能周期及服务标准</div>
          <div className="header-season-dialog-content">
            <div className="header-season-dialog-table-header">
              <div className="header-season-dialog-table-row">
                <span className="header-season-dialog-table-cell" />
                <span className="header-season-dialog-table-cell">开始日期</span>
                <span className="header-season-dialog-table-cell">服务标准</span>
              </div>
            </div>
            <div className="header-season-dialog-table-body">
              {SEASONS.map((item) => {
                return (
                  <div
                    key={item.key}
                    className={classNames('header-season-dialog-table-row', {
                      'header-season-dialog-table-row-current': item.key === season,
                    })}
                  >
                    <span className="header-season-dialog-table-cell">
                      <img src={item.img} />
                      <span>{item.name}：</span>
                    </span>
                    <span className="header-season-dialog-table-cell">
                      {edit ? (
                        <BdiaInput
                          style={{ width: '0.71rem' }}
                          size="large"
                          value={editDate[item.m][0]}
                          status={editDate[item.m][1] ? 'error' : ''}
                          type="number"
                          onChange={(e) => handleMonthChange(e, item.m)}
                        />
                      ) : (
                        <span className="header-season-dialog-table-cell-num">{date[item.m][0]}</span>
                      )}
                      <span style={{ paddingLeft: '0.07rem', paddingRight: '0.36rem' }}>月</span>
                      {edit ? (
                        <BdiaInput
                          style={{ width: '0.71rem' }}
                          size="large"
                          value={editDate[item.d][0]}
                          status={editDate[item.d][1] ? 'error' : ''}
                          type="number"
                          onChange={(e) => handleDateChange(e, item.d)}
                        />
                      ) : (
                        <span className="header-season-dialog-table-cell-num">{date[item.d][0]}</span>
                      )}
                      <span style={{ paddingLeft: '0.07rem' }}>日</span>
                    </span>
                    <span className="header-season-dialog-table-cell">
                      {edit ? (
                        <BdiaInput
                          style={{ width: '0.71rem' }}
                          size="large"
                          type="number"
                          value={editDate[item.min][0]}
                          status={editDate[item.min][1] ? 'error' : ''}
                          onChange={(e) => handleMinChange(e, item.min)}
                        />
                      ) : (
                        <span className="header-season-dialog-table-cell-num">{date[item.min][0]}</span>
                      )}
                      <span style={{ paddingLeft: '0.05rem' }}>℃</span>
                      <span style={{ padding: '0 0.13rem 0 0.11rem' }}>—</span>
                      {edit ? (
                        <BdiaInput
                          style={{ width: '0.71rem' }}
                          size="large"
                          type="number"
                          value={editDate[item.max][0]}
                          status={editDate[item.max][1] ? 'error' : ''}
                          onChange={(e) => handleMaxChange(e, item.max)}
                        />
                      ) : (
                        <span className="header-season-dialog-table-cell-num">{date[item.max][0]}</span>
                      )}
                      <span style={{ paddingLeft: '0.05rem' }}>℃</span>
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="header-season-dialog-btns">
            {edit ? (
              <>
                <BdiaButton gray onClick={handleCancel}>
                  取消
                </BdiaButton>
                <BdiaButton onClick={handleSave}>保存</BdiaButton>
              </>
            ) : (
              <>
                <BdiaButton gray onClick={handleClose}>
                  关闭
                </BdiaButton>
                <BdiaButton onClick={() => setEdit(true)}>编辑</BdiaButton>
              </>
            )}
          </div>
        </BdiaDialog>
      )}
    </>
  );
}
interface SeasonProps {
  season: EnumSeason;
  onClose: Void;
  updateSeason: Void;
}
interface SeasonItem {
  name: string;
  img: string;
  key: EnumSeason;
  color: string;
  highlightColor: string;
  m: string;
  d: string;
  min: string;
  max: string;
}
