import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import BdiaDialog from '@/components/BdiaDialog/BdiaDialog';
import BdiaInput from '@/components/BdiaInput/BdiaInput';
import BdiaButton from '@/components/BdiaButton/BdiaButton';
import BdiaConfirm from '@/components/BdiaConfirm/BdiaConfirm';
import { putUnlock, logout } from '@/api/login';
import { RsaEncryption, WatchScreenLock } from '@/utils/general';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { toggleLock } from '@/store/features/commonSlice';
import './LockDialog.less';
import Cookie from '@/utils/cookie';

const LockDialog = () => {
  const dispatch = useAppDispatch();
  const { userinfo, token } = useAppSelector((state) => state.common);
  const navigate = useNavigate();
  const location = useLocation();
  const [password, setPassword] = React.useState('');
  const [pwdError, setPwdError] = React.useState(false);
  const [confirmVisible, setConfirmVisible] = React.useState(false);

  const handleLogout = () => {
    setConfirmVisible(true);
  };

  const handleConfirmCancel = () => {
    setConfirmVisible(false);
  };

  const handleConfirmOk = () => {
    dispatch(toggleLock(false));
    dispatch(logout()).then(() => {
      navigate('/login');
      // 清除锁屏定时器
      WatchScreenLock.clear();
    });
  };

  const handlePwdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleUnlock = () => {
    if (!password) {
      setPwdError(true);
      return;
    }
    // 锁屏时已将cookie清空，为了解锁接口能正常调用，set cookie
    Cookie.setCookie('token', token);
    dispatch(putUnlock({ password: RsaEncryption(password) })).then((res) => {
      if (res.payload.code === 0 && res.payload.data.result) {
        WatchScreenLock.set(userinfo.autoLockTime);
      } else {
        if (!res.payload.data.result) {
          setPwdError(true);
        }
        Cookie.setCookie('token', '');
      }
    });
  };

  return (
    <>
      <BdiaDialog size="large">
        <div className="lock-dialog">
          <div className="lock-dialog-user">
            <div className="lock-dialog-user-circle">
              <span className="iconfont icon-as-user" />
            </div>
            <p>{userinfo.username}</p>
          </div>
          <div className="lock-dialog-input">
            <BdiaInput.Group compact>
              <BdiaInput.Password
                value={password}
                onChange={handlePwdChange}
                size="large"
                type="password"
                style={{ width: 'calc(100% - 0.56rem)' }}
                onKeyDown={(e) => e.key === 'Enter' && handleUnlock()}
              />
              <BdiaButton inputBtn gray>
                <span className="iconfont icon-youjiantou-" onClick={handleUnlock} />
              </BdiaButton>
            </BdiaInput.Group>
          </div>
          <p className={classNames('lock-dialog-pwd-error', { 'lock-dialog-pwd-error-hidden': pwdError })}>
            <span className="iconfont icon-tishi " />
            密码错误
          </p>
          <span className="iconfont icon-guanbiruanjian lock-dialog-logout" onClick={handleLogout} />
        </div>
      </BdiaDialog>
      {confirmVisible && <BdiaConfirm content="确定退出登录？" onCancel={handleConfirmCancel} onOk={handleConfirmOk} />}
    </>
  );
};
export default LockDialog;
