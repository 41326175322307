import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { MenuArray, PROJECT_MENU } from '@/config/project.dx';
import { useAppSelector } from '@/store/hooks';
import { EnumUserRole } from '@/typings/enum';
import './Footer.less';

const Footer = () => {
  const [path, setPath] = React.useState('');
  const location = useLocation();
  const userinfo = useAppSelector((state) => state.common.userinfo);
  const [menulist, setMenulist] = useState<MenuArray[]>([]);

  useEffect(() => {
    getMenus();
  }, [location]);

  useEffect(() => {
    getMenus();
  }, [userinfo.role]);

  const getMenus = () => {
    const { pathname } = location;
    const moduleName = pathname.split('/')[1];
    // 更新底部菜单
    const list = PROJECT_MENU[moduleName] || [];
    const resList = list.filter((item) => {
      if (item.auth === 'ALL') {
        return true;
      }
      const roleConcat: EnumUserRole[] = [...userinfo.role, ...item.auth];
      if (new Set(roleConcat).size === roleConcat.length) {
        return false;
      }
      return true;
    });
    setMenulist(resList);
    setPath(pathname);
  };

  return (
    <footer className="footer">
      {menulist.map((menu) => {
        return (
          <Link
            to={menu.path}
            key={menu.en}
            className={classnames('footer-link', { 'active-link': menu.path === path })}
          >
            {menu.cn}
          </Link>
        );
      })}
    </footer>
  );
};
export default Footer;
