import React, { lazy } from 'react';
import { RouteObject, Navigate, useLocation } from 'react-router-dom';
import PageContent from './comps/Content/Content';
import Cookie from '@/utils/cookie';

const LoginPage = lazy(() => import('@/views/Login/Login'));
const SceneSelectPage = lazy(() => import('@/views/SceneSelect/SceneSelect'));
const UserPage = lazy(() => import('@/views/User/User'));
// 照明
const LampControlPage = lazy(() => import('@/views/LampControl/LampControl'));
const LampSystemPage = lazy(() => import('@/views/LampSystem/LampSystem'));
const LampLogPage = lazy(() => import('@/views/LampLog/LampLog'));
// 空调
const ACControlPage = lazy(() => import('@/views/ACControl/ACControl'));
const ACSystemPage = lazy(() => import('@/views/ACSystem/ACSystem'));
const ACLogPage = lazy(() => import('@/views/ACLog/ACLog'));

// 碳排
const CarbonOverviewPage = lazy(() => import('@/views/CarbonOverview/CarbonOverview'));
const CarbonSystemPage = lazy(() => import('@/views/CarbonSystem/CarbonSystem'));
const CarbonDataPage = lazy(() => import('@/views/CarbonData/CarbonData'));

// 能耗
const LampEnergyPage = lazy(() => import('@/views/EnergyLamp/EnergyLamp'));

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const isLogin = Cookie.getCookie('token');
  const location = useLocation();
  if (!isLogin) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  if (location.pathname === '/') {
    return <Navigate to="/select" replace />;
  }
  return children;
};

const routes: RouteObject[] = [
  {
    path: '/',
    element: <PageContent />,
    children: [
      {
        path: '/select',
        element: (
          <RequireAuth>
            <SceneSelectPage />
          </RequireAuth>
        ),
      },
      {
        path: '/user',
        element: (
          <RequireAuth>
            <UserPage />
          </RequireAuth>
        ),
      },
      {
        path: '/lamp/control',
        element: (
          <RequireAuth>
            <LampControlPage />
          </RequireAuth>
        ),
      },
      {
        path: '/lamp/system',
        element: (
          <RequireAuth>
            <LampSystemPage />
          </RequireAuth>
        ),
      },
      {
        path: '/lamp/log',
        element: (
          <RequireAuth>
            <LampLogPage />
          </RequireAuth>
        ),
      },
      {
        path: '/ac/control',
        element: (
          <RequireAuth>
            <ACControlPage />
          </RequireAuth>
        ),
      },
      {
        path: '/ac/system',
        element: (
          <RequireAuth>
            <ACSystemPage />
          </RequireAuth>
        ),
      },
      {
        path: '/ac/log',
        element: (
          <RequireAuth>
            <ACLogPage />
          </RequireAuth>
        ),
      },
      {
        path: '/carbon/overview',
        element: (
          <RequireAuth>
            <CarbonOverviewPage />
          </RequireAuth>
        ),
      },
      {
        path: '/carbon/system',
        element: (
          <RequireAuth>
            <CarbonSystemPage />
          </RequireAuth>
        ),
      },
      {
        path: '/carbon/data',
        element: (
          <RequireAuth>
            <CarbonDataPage />
          </RequireAuth>
        ),
      },
      {
        path: '/energy/lamp',
        element: (
          <RequireAuth>
            <LampEnergyPage />
          </RequireAuth>
        ),
      },
      {
        path: '/',
        element: (
          <RequireAuth>
            <LampControlPage />
          </RequireAuth>
        ),
      },
    ],
  },
  {
    path: '/login',
    element: <LoginPage />,
  },
  {
    path: '*',
    element: <div>no match</div>,
  },
];

export default routes;
