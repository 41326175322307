import { createSlice } from '@reduxjs/toolkit';
import { EnumGroupStatus, EnumGroupMode } from '@/typings/enum';
import { getAreaList, getBaseGroupList } from '@/api/acControl';
import { ViewOptions } from 'ol/View';
import { Coordinate } from 'ol/coordinate';
import { boundingExtent } from 'ol/extent';

export interface MapViewOptions extends Omit<ViewOptions, 'extent'> {
  extent: Coordinate[];
}

export interface ACGroup {
  code: string;
  name: string;
  groupType: string;
  currentMode: EnumGroupMode;
  status: EnumGroupStatus;
  planName: string;
  id: number;
  controlArea: string;
  baseGroups: string[]; // 接口中没有，手动添加这个字段是为了方面使用地图组件
}
interface AreaType {
  name: string;
  id: number;
  gisConfig: {
    backgroundUrl: string;
    tiles: string;
    images: string;
    source: string;
  };
  show: ViewOptions;
}
interface ACState {
  acList: ACGroup[];
  shortcuts: number;
  areaList: AreaType[];
  currentArea: { id: number; name: string };
  tempRange: [number, number];
  tempCtrlAttrs: { field: string; name: string }[];
}

const initialState: ACState = {
  acList: [],
  shortcuts: 0, // 数字累加，用于监听快捷操作
  areaList: [],
  currentArea: { id: 0, name: '' },
  tempRange: [0, 0], // 当前季节的标准温度范围
  tempCtrlAttrs: [], // 当前季节进行控制时，可以配置的参数
};

export const acSlice = createSlice({
  name: 'lamp',
  initialState,
  reducers: {
    // ws灯组状态更新
    updateACListStatus: (state, { payload }: { payload: { code: string; status: EnumGroupStatus; bgn: number }[] }) => {
      const { acList } = state;
      acList.forEach((item) => {
        const exist = payload.find((data) => data.code === item.code);
        if (exist) {
          item.status = exist.status;
        }
      });
      state.acList = acList;
    },
    // ws灯组模式更新
    updateACListMode: (state, { payload }) => {
      const { manualAll, manual, auto }: { manualAll: boolean; manual?: string[]; auto?: string[] } = payload;
      const { acList } = state;
      if (manualAll) {
        acList.forEach((item) => {
          item.currentMode = EnumGroupMode.MANUAL;
        });
      } else {
        acList.forEach((item) => {
          if (manual && manual.indexOf(item.code) >= 0) {
            item.currentMode = EnumGroupMode.MANUAL;
          } else if (auto && auto.indexOf(item.code) >= 0) {
            item.currentMode = EnumGroupMode.AUTOMATIC;
          }
        });
      }
      state.acList = acList;
    },
    updateAcCurrentArea: (state, { payload }) => {
      state.currentArea = payload;
    },
    updateAcTempRange: (state, { payload }) => {
      state.tempRange = payload;
    },
    updateAcTempCtrlAttrs: (state, { payload }) => {
      state.tempCtrlAttrs = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAreaList.fulfilled, (state, { payload }) => {
      const { data } = payload;
      const res: AreaType[] = [];
      data.forEach((item: { name: string; id: number; gisConfig: string; show: MapViewOptions }) => {
        const { gisConfig, show, ...rest } = item;

        const obj: AreaType = {
          ...rest,
          gisConfig: JSON.parse(item.gisConfig),
          show: {
            ...show,
            extent: boundingExtent(show.extent),
          },
        };
        res.push(obj);
      });
      state.areaList = res;
    });
    builder.addCase(getBaseGroupList.fulfilled, (state, { payload }) => {
      const list: ACGroup[] = [];
      payload.data.forEach((item: ACGroup) => {
        list.push({
          ...item,
          baseGroups: [item.code],
        });
      });
      state.acList = list;
    });
  },
});

export const { updateACListStatus, updateACListMode, updateAcCurrentArea, updateAcTempRange, updateAcTempCtrlAttrs } =
  acSlice.actions;

export default acSlice.reducer;
