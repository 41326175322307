import { EnumSeason } from '@/typings/enum';
import request from './request';
import { EnumAcSchemeMode, RenderTempCfgParams } from '@/views/ACSystem/comps/SchemeDialog/SchemeDialog';
// 供能周期获取
export const getSupplyCycle = () => {
  return request('air_con/supply/cycle', {
    method: 'GET',
  });
};
// 供能周期更新
export const updateSupplyCycle = (params: SupplyCycleParams) => {
  return request('air_con/supply/cycle', {
    method: 'PUT',
    body: params,
  });
};
export interface SupplyCycleParams {
  spring: string;
  summer: string;
  autumn: string;
  winter: string;
  springTmpMin: number;
  springTmpMax: number;
  summerTmpMin: number;
  summerTmpMax: number;
  autumnTmpMin: number;
  autumnTmpMax: number;
  winterTmpMin: number;
  winterTmpMax: number;
}
// 区域设计查询
export const getAreaSetting = (params: { areaId: number }) => {
  return request('plan/air_con/control-config', {
    method: 'GET',
    body: params,
  });
};
// 控制时间设置
export const updateSysCtrlTime = (params: { startTime: string | null; endTime: string | null; areaId: number }) => {
  return request('plan/air_con/control-time', {
    method: 'PUT',
    body: params,
  });
};
export const updateSysPlanCp = (params: { x: number; y: number; areaId: number }) => {
  return request('plan/air_con/control-param', {
    method: 'PUT',
    body: params,
  });
};
// 获取某区域下的类别
export const getACTypes = (params: { areaId: number }) => {
  return request('air_con/type', {
    method: 'GET',
    body: params,
  });
};
// 根据类别获取温控方案
export const getTempSchemeByType = (params: { areaId: number; typeId: number }) => {
  return request('plan/air_con/temp-plan/bytype', {
    method: 'GET',
    body: params,
  });
};
// 更新温控方案
export const updateTempScheme = (params: {
  areaId: number;
  groupTypeId: number;
  season: EnumSeason;
  mode: EnumAcSchemeMode;
  cons: RenderTempCfgParams[] | null;
}) => {
  return request('plan/air_con/temp-plan/bytype', {
    method: 'POST',
    body: params,
  });
};
// 获取运行方案列表
export const getRunningSchemeList = (params: { areaId: number; typeId: number }) => {
  return request('plan/air_con/unit/list/bytype', {
    method: 'GET',
    body: params,
  });
};
// 新增方案名称
export const addRunningSchemeName = (params: { name: string; areaId: number; groupTypeId: number }) => {
  return request('plan/air_con/unit/add', {
    method: 'POST',
    body: params,
  });
};
// 更新方案名称
export const updateRunningSchemeName = (params: { name: string; areaId: number; groupTypeId: number; id: number }) => {
  return request('plan/air_con/unit/update', {
    method: 'PUT',
    body: params,
  });
};
// 启用方案
export const startRunningScheme = (params: { effect: boolean; areaId: number; id: number }) => {
  return request('plan/air_con/unit/apply-plan', {
    method: 'PUT',
    body: params,
  });
};
// 删除方案
export const delRunningScheme = (params: { id: number; areaId: number }) => {
  return request('plan/air_con/unit/delete-plan', {
    method: 'DELETE',
    body: params,
  });
};
// 获取运行方案详情
export const getRunningSchemeDetail = (params: { id: number; areaId: number }) => {
  return request('plan/air_con/unit/detail', {
    method: 'GET',
    body: params,
  });
};
// 更新运行方案详情
export const updateRunningSchemeDetail = (params: {
  id: number;
  areaId: number;
  airConPlan: { mode: string; name: string; codes: string[] }[];
}) => {
  return request('plan/air_con/unit/detail', {
    method: 'PUT',
    body: params,
  });
};
// 获取所有季节控制参数
export const getSeasonsCtrlParams = () => {
  return request('air_con/control_attrs', {
    method: 'GET',
  });
};
