import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from '@/components/BrowserRouter/BrowserRouter';
import Layout from '@/views/Layout/Layout';
import { InitUIScene } from '@/utils/general';
import { store } from './store';
import './index.less';
// // 是否需要隐藏掉DX相关的文本或图片
window.isSensitive = false;
if (!window.isSensitive) {
  document.title = '大兴机场航班联动智能控制系统';
} else {
  document.title = 'xxxxx';
}

InitUIScene();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Layout />
    </BrowserRouter>
  </Provider>,
);
