import React, { ReactNode } from 'react';
import BdiaButton from '../BdiaButton/BdiaButton';
import './BdiaConfirm.less';

interface BdiaConfirmProps {
  title?: boolean | string; // 标题
  content: string | ReactNode; // 内容
  okText?: string; // 确认按钮文案
  cancelText?: string; // 取消按钮文案
  onOk?: Void; // 确认按钮点击事件
  onCancel?: Void; // 取消按钮点击事件
}

const BdiaConfirm = ({ title, content, okText, cancelText, onOk, onCancel }: BdiaConfirmProps) => {
  return (
    <div className="bdia-confirm-mask">
      <div className="bdia-confirm">
        {title && <div className="bdia-confirm-title">{typeof title === 'string' ? title : '提示'}</div>}
        <div className="bdia-confirm-content">{content}</div>
        <div className="bdia-confirm-btns">
          {onCancel && (
            <BdiaButton gray onClick={() => onCancel?.()}>
              {cancelText || '取消'}
            </BdiaButton>
          )}
          <BdiaButton onClick={() => onOk?.()}>{okText || '确认'}</BdiaButton>
        </div>
      </div>
    </div>
  );
};
BdiaConfirm.defaultProps = {
  title: true,
};
export default BdiaConfirm;
