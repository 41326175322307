import { transformExtent } from 'ol/proj';
import { boundingExtent } from 'ol/extent';
import { EnumUserRole } from '@/typings/enum';
export interface MenuArray {
  cn: string;
  en: string;
  path: string;
  auth: 'ALL' | EnumUserRole[];
  headers: string[]; // home-返回主页 year-年份选择 lampArea-空调区域 acArea-机组区域 season-当前季节 setting-人员管理
}
export const PROJECT_MENU: {
  [key: string]: MenuArray[];
} = {
  lamp: [
    { cn: '灯组控制', en: 'lamp', path: '/lamp/control', auth: 'ALL', headers: ['home', 'lampArea'] },
    // { cn: '能耗分析', en: 'energy', path: '/lamp/energy', auth: 'ALL', headers: ['home', 'lampArea'] },
    {
      cn: '系统设置',
      en: 'system',
      path: '/lamp/system',
      auth: [EnumUserRole.SUPER_ADMIN, EnumUserRole.LIGHT_ADMIN],
      headers: ['home'],
    },
    { cn: '控制日志', en: 'log', path: '/lamp/log', auth: 'ALL', headers: ['home'] },
  ],
  ac: [
    { cn: '机组控制', en: 'lamp', path: '/ac/control', auth: 'ALL', headers: ['home', 'acArea'] },
    {
      cn: '系统设置',
      en: 'system',
      path: '/ac/system',
      auth: [EnumUserRole.SUPER_ADMIN, EnumUserRole.LIGHT_ADMIN],
      headers: ['home', 'acArea'],
    },
    { cn: '控制日志', en: 'log', path: '/ac/log', auth: 'ALL', headers: ['home', 'acArea'] },
  ],
  carbon: [
    { cn: '总览', en: 'overview', path: '/carbon/overview', auth: 'ALL', headers: ['home', 'year'] },
    {
      cn: '系统设置',
      en: 'system',
      path: '/carbon/system',
      auth: [EnumUserRole.SUPER_ADMIN, EnumUserRole.CARBON_EMISSION_ADMIN],
      headers: ['home', 'year'],
    },
    {
      cn: '数据管理',
      en: 'data',
      path: '/carbon/data',
      auth: [EnumUserRole.SUPER_ADMIN, EnumUserRole.CARBON_EMISSION_ADMIN],
      headers: ['home', 'year'],
    },
  ],
  energy: [
    {
      cn: '照明',
      en: 'lamp',
      path: '/energy/lamp',
      auth: [EnumUserRole.SUPER_ADMIN, EnumUserRole.ENERGY_ANALYSIS_VIEW],
      headers: ['home'],
    },
  ],
};

export const LAMP_MAP_OPTIONS = {
  center: [114.6136, 38.0486],
  Zoom: 12.2,
  maxZoom: 18,
  minZoom: 10,
};

// FIX：直接调用了view.set方法不太好使，先用下面的方式处理
export interface MapViewFnNameType {
  center: string;
  zoom: string;
  maxZoom?: string;
  minZoom?: string;
  extent?: string;
  pan?: string; // 是否禁止拖拽
}
export interface MapOptionsType {
  [name: string]: {
    [id: number]: {
      [key in keyof MapViewFnNameType]: any;
    };
  };
}

export const MAP_OPTIONS: MapOptionsType = {
  '/lamp': {
    // 国内
    1: {
      center: [114.58968437057516, 38.054638852361144],
      zoom: 12.7,
      maxZoom: 14,
      minZoom: 12,
      extent: boundingExtent([
        [114.300225622559, 37.9050226806641],
        [114.869766809082, 38.1858421875],
      ]),
    },
    // 国际
    2: {
      center: [114.57101982411133, 38.03599147587997],
      zoom: 13.2,
      maxZoom: 14,
      minZoom: 12,
      extent: boundingExtent([
        [114.302825622559, 37.9251226806641],
        [114.868166809082, 38.1657421875],
      ]),
    },
    // 大屋面
    3: {
      center: [114.59027156923354, 37.971173197932366],
      zoom: 12.7,
      maxZoom: 14,
      minZoom: 12,
      extent: boundingExtent([
        [114.202855622559, 37.7801226806641],
        [114.968196809082, 38.1697421875],
      ]),
    },
  },
  '/system/planedit': {
    1: {
      center: [114.70484389440152, 38.047950127292445],
      zoom: 12,
      maxZoom: 13,
      minZoom: 11,
      extent: boundingExtent([
        [114.100225622559, 37.7050226806641],
        [115.169766809082, 38.3858421875],
      ]),
    },
    2: {
      center: [114.64252120881164, 38.03769645160682],
      zoom: 12.7,
      maxZoom: 14,
      minZoom: 12,
      extent: boundingExtent([
        [114.302825622559, 37.8651226806641],
        [114.938166809082, 38.2057421875],
      ]),
    },
    3: {
      center: [114.64531178868707, 37.9770817138501],
      zoom: 12.7,
      maxZoom: 14,
      minZoom: 12,
      extent: boundingExtent([
        [114.302855622559, 37.7801226806641],
        [114.968196809082, 38.1697421875],
      ]),
    },
  },
  '/ac': {
    4: {
      center: [114.62913012637951, 38.041814408576734],
      zoom: 15,
      maxZoom: 16,
      minZoom: 14,
      extent: boundingExtent([
        [114.5091301637951, 37.96181408576734],
        [114.768196809082, 38.1097421875],
      ]),
    },
  },
};
export const BASE_LAYER_NAME = 'base_layer';

// 密码加密公钥
export const publickKey =
  'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEArGmsEejJCVULANtBLhUh20kcbdkHmcVPZlrG64OccE/O9N4sD6Dm+oQoHGAxehNNnJXeL4/ZrdQELUQhfk6EcvBYdFpgpnvDbavojaZ2Za2/I4Tm3Rb9p9TQN3B42q3d/wlUFeM0x32ogwhmEOmuv06koBDdtl7z2MysjSiT9DMzKQdvjS4Jv/LeU0+FPyn+AYTU+owNchkZjsg/PsS4G79SgPJ9up6ad9W3Ah6F3hOqe3nSo8rxzfF/slqiGbi3ZaC//5QC0gQnUHpvkg34ZFH8TTIQcG6EnTnKZeyzqEKTvyQkb6zAvA6Vnb7GMURnch0xKl/ojWTGOlSQY5+5pwIDAQAB';

export const PAGE_SIZE = 50;
