import React from 'react';
import { useNavigate } from 'react-router-dom';
import BdiaMessage from '@/components/BdiaMessage/BdiaMessage';
import Dialog from '@/components/BdiaDialog/BdiaDialog';
import BdiaInput from '@/components/BdiaInput/BdiaInput';
import BdiaButton from '@/components/BdiaButton/BdiaButton';
import BdiaSelect from '@/components/BdiaSelect/BdiaSelect';
import BdiaConfirm from '@/components/BdiaConfirm/BdiaConfirm';
import Cookie from '@/utils/cookie';
import { RsaEncryption, WatchScreenLock } from '@/utils/general';
import { putLockTime, putPassword, logout } from '@/api/login';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { toggleLoading, updateUserinfo } from '@/store/features/commonSlice';
import './UserDialog.less';

interface dialogProps {
  handleClose: () => void;
}

interface LockOpt {
  label: string;
  value: number;
}
const LOCK_OPTIONS: LockOpt[] = [
  { label: '5分钟', value: 5 * 60 },
  { label: '10分钟', value: 10 * 60 },
  { label: '20分钟', value: 20 * 60 },
  { label: '30分钟', value: 30 * 60 },
  { label: '1小时', value: 60 * 60 },
  { label: '从不', value: 0 },
];

const UserDialog = ({ handleClose }: dialogProps) => {
  const userinfo = useAppSelector((state) => state.common.userinfo);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [oldPassword, setOldPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');

  const [oldLocktime, setOldLocktime] = React.useState(userinfo.autoLockTime);
  const [autoLockTime, setAutoLockTime] = React.useState(userinfo.autoLockTime);
  const [confirmVisible, setConfirmVisible] = React.useState(false);

  const [confirmContent, setConfirmContent] = React.useState('');
  const [confirmType, setConfirmType] = React.useState<'pwd' | 'logout'>('pwd');

  const handleLockChange = (value: unknown) => {
    setAutoLockTime(value as number);
    setConfirm(true, { type: 'pwd', lockTime: value as number });
  };

  const setConfirm = (visible: boolean, opts?: { type?: 'pwd' | 'logout'; lockTime?: number }) => {
    if (!visible) {
      setConfirmContent('');
    } else {
      switch (opts?.type || confirmType) {
        case 'pwd':
          setConfirmContent(
            `确定将自动锁屏时间更改为“${LOCK_OPTIONS.find((item) => item.value === opts?.lockTime)?.label}”？`,
          );
          break;
        case 'logout':
          setConfirmContent('确定退出登录？');
          break;
      }
    }
    setConfirmVisible(visible);
    opts?.type && setConfirmType(opts.type);
  };

  const handleOldPwdChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setOldPassword(evt.target.value);
  };

  const handleNewPwdChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(evt.target.value);
  };

  const handleConfirmPwdChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(evt.target.value);
  };

  const handlePwdChange = () => {
    if (!oldPassword || !newPassword || !confirmPassword) {
      BdiaMessage.error(`请输入${!oldPassword ? '旧密码' : !newPassword ? '新密码' : '确认密码'}`);
      return;
    }
    if (newPassword !== confirmPassword) {
      BdiaMessage.error('两次输入的新密码不一致');
      return;
    }
    if (newPassword === oldPassword) {
      BdiaMessage.error('新旧密码不能相同');
      return;
    }
    dispatch(toggleLoading(true));
    putPassword({
      oldPassword: RsaEncryption(oldPassword),
      newPassword: RsaEncryption(newPassword),
    }).then((res) => {
      if (res.code === 0) {
        BdiaMessage.info('请重新登录');
        handleClose();
        Cookie.setCookie('token', '');
        setTimeout(() => {
          // 跳转登录页
          dispatch(toggleLoading(false));
          navigate('/login');
        }, 2000);
      } else {
        dispatch(toggleLoading(false));
      }
    });
  };

  const handleUpdateLocktime = () => {
    dispatch(toggleLoading(true));
    putLockTime({ autoLockTime }).then((res) => {
      if (res.code === 0) {
        BdiaMessage.success('锁屏时间已修改');
        setConfirm(false);
        setOldLocktime(autoLockTime);
        dispatch(updateUserinfo({ ...userinfo, autoLockTime }));
        WatchScreenLock.set(autoLockTime);
      } else {
        handleLocktimeCancel();
      }
      dispatch(toggleLoading(false));
    });
  };

  const handleLocktimeCancel = () => {
    setConfirm(false);
    // 保存失败，置为上次的保存值
    setAutoLockTime(oldLocktime);
  };

  const handleLogout = () => {
    dispatch(toggleLoading(true));
    dispatch(logout()).then(() => {
      handleClose();
      dispatch(toggleLoading(false));
      navigate('/login');
    });
  };

  const handleConfirmOk = () => {
    switch (confirmType) {
      case 'pwd':
        handleUpdateLocktime();
        break;
      case 'logout':
        handleLogout();
        break;
    }
  };

  const handleConfirmCancel = () => {
    switch (confirmType) {
      case 'pwd':
        handleLocktimeCancel();
        break;
      case 'logout':
        setConfirm(false);
    }
  };
  return (
    <>
      <Dialog size="large" style={{ height: 'auto' }}>
        <div className="user-dialog">
          <span className="iconfont icon-as-close" onClick={handleClose} />
          <section>
            <div className="user-section-title">基本信息</div>
            <div className="user-info">
              <div>
                <p className="user-font-2">姓名</p>
                <p className="user-info-value">{userinfo.realName}</p>
              </div>
              <div>
                <p className="user-font-2">用户名</p>
                <p className="user-info-value">{userinfo.username}</p>
              </div>
            </div>
          </section>
          <section>
            <div className="user-section-title">修改密码</div>
            <div className="pwd-input-item">
              <label className="pwd-input-label">旧密码</label>
              <div className="pwd-input-box">
                <BdiaInput onChange={handleOldPwdChange} value={oldPassword} size="large" type="password" />
              </div>
            </div>
            <div className="pwd-input-item">
              <label className="pwd-input-label">新密码</label>
              <div className="pwd-input-box">
                <BdiaInput onChange={handleNewPwdChange} value={newPassword} size="large" type="password" />
              </div>
            </div>
            <div className="pwd-input-item">
              <label className="pwd-input-label">确认密码</label>
              <div className="pwd-confirm">
                <div className="pwd-input-box">
                  <BdiaInput onChange={handleConfirmPwdChange} value={confirmPassword} size="large" type="password" />
                </div>
                <BdiaButton onClick={handlePwdChange}>确认</BdiaButton>
              </div>
            </div>
          </section>
          <section>
            <div className="user-section-title lock-section-title">自动锁屏</div>
            <BdiaSelect
              value={autoLockTime}
              onChange={handleLockChange}
              themeStyle="white"
              style={{ width: '1.6rem' }}
              options={LOCK_OPTIONS}
            />
          </section>
          {/* <BdiaButton onClick={() => setConfirm(true, { type: 'logout' })} block className="user-dialog-login-btn">
            退出登录
          </BdiaButton> */}
        </div>
      </Dialog>
      {confirmVisible && <BdiaConfirm content={confirmContent} onCancel={handleConfirmCancel} onOk={handleConfirmOk} />}
    </>
  );
};

export default UserDialog;
