/* eslint-disable @typescript-eslint/explicit-member-accessibility */

import Cookie from './cookie';
import { store } from '@/store';

// 对webscoket的封装，支持链接、断开链接、消息的订阅和取消
class WebSocketClass {
  constructor() {
    this.socket = null;
    this.heartSendTimer = null; // 心跳
    this.reconnectTimer = null; // 重连心跳
    this.heartStatus = '';
    this.subscribeList = {};
    this.openEvtList = {};
    this.worker = null;
  }

  /**
   *
   * @param type 与后端约定的type
   * @param name 自定义的回调名称，用于取消订阅
   * @param cb 回调
   */
  subscribeWS(type, name, cb) {
    if (this.socket) {
      if (this.socket.readyState === WebSocket.CLOSED || this.socket.readyState === WebSocket.CLOSING) {
        this.close();
        this.connect();
      }
    } else {
      this.connect();
    }
    if (!this.subscribeList[type]) {
      this.subscribeList[type] = {};
    }
    this.subscribeList[type][name] = cb;
  }

  unSubscribeWS(type, name) {
    console.log('unSubscribeWS', this.subscribeList[type]);
    if (this.subscribeList[type]) {
      this.subscribeList[type][name] = null;
    }
  }

  // 直接关闭ws
  close() {
    console.log('websocket close');
    this.heartSendTimer && clearInterval(this.heartSendTimer);
    if (this.reconnectTimer) {
      clearTimeout(this.reconnectTimer);
    }
    this.heartStatus = '';
    if (this.socket && this.socket.readyState !== WebSocket.CLOSED) {
      this.socket.close();
    }
  }
  // 关闭ws，并通过shared worker通知其他port
  safeClose() {
    this.close();
    if (this.worker) {
      this.worker.port.postMessage('safeClose'); // 为了是其他的页面能够保持链接
      this.worker.port.close();
      this.worker = null;
    }
  }

  updateOpenEvt(name, fn, type) {
    if (type === 'add') {
      this.openEvtList[name] = fn;
    } else {
      this.openEvtList[name] = null;
    }
  }

  connect() {
    const token = Cookie.getCookie('token') || store.getState().common.token;
    if (!token) {
      return;
    }
    let w = this.worker;
    if (window.SharedWorker) {
      if (!w) {
        w = new SharedWorker(new URL('./worker.js', import.meta.url));
        w.port.onmessage = (e) => {
          const data = e.data;
          // console.log('receive msg', data);
          if (data === 'reconnect') {
            console.log('重新链接ws');
            this.connect();
          } else {
            try {
              const data = JSON.parse(e.data);
              if (data.type === -1) {
                this.close();
              } else if (data.type === 0 && data.data.message === 'heartbeat') {
                this.heartStatus = 'back';
              } else {
                const fnObj = this.subscribeList[data.type];
                if (fnObj) {
                  Object.keys(fnObj).forEach((name) => {
                    fnObj[name]?.(data);
                  });
                }
              }
            } catch {}
          }
        };
        w.port.start();
        this.worker = w;
      }
    }
    const url = process.env.REACT_APP_WS + '?Authorization=' + token;
    this.socket = new WebSocket(url);
    this.socket.onopen = (e) => {
      console.log('websocket connect success', w);
      Object.keys(this.openEvtList).forEach((k) => {
        this.openEvtList[k]?.();
      });
      this.heart();
    };
    this.socket.onmessage = (e) => {
      w.port.postMessage(e.data);
    };
    this.socket.onerror = (e) => {
      console.log('websocket onerror', e);
      this.close();
      this.reconnectTimer = setTimeout(() => {
        this.connect();
      }, 5 * 1000);
    };
    this.socket.onclose = (e) => {
      console.log('websocket onclose', e);
    };
  }

  send(msg) {
    if (this.socket) {
      if (this.socket.readyState === WebSocket.OPEN) {
        this.socket.send(JSON.stringify(msg));
      }
    }
  }

  heart() {
    this.heartSendTimer && clearInterval(this.heartSendTimer);

    this.heartSendTimer = setInterval(() => {
      if (this.socket) {
        if (this.heartStatus === 'go') {
          this.close();
          this.connect();
        } else {
          if (this.socket.readyState === WebSocket.OPEN) {
            this.heartStatus = 'go';
            this.send({
              type: 0,
              message: 'heartbeat',
            });
          } else if (this.socket.readyState === WebSocket.CLOSED || this.socket.readyState === WebSocket.CLOSING) {
            this.close();
            this.connect();
          }
        }
      } else {
        this.connect();
      }
    }, 15 * 1000);
  }
}

const ws = new WebSocketClass();
export default ws;
