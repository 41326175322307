import React from 'react';
import classnames from 'classnames';
import './BdiaDialog.less';
interface DialogProps {
  size?: 'small' | 'large';
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}
const Dialog = ({ size = 'large', children, style, className }: DialogProps) => {
  return (
    <div className="dialog-mask">
      <div className={classnames('dialog', `${size}-dialog`, className)} style={style}>
        {children}
      </div>
    </div>
  );
};
export default Dialog;
