/**
 * size:
 *  middle 对应UI的高度1 36px
 *  large：对应UI中的高度2 48px
 */
import React from 'react';
import classNames from 'classnames';
import { Input, InputRef } from 'antd';
import { InputProps, GroupProps, PasswordProps, TextAreaProps } from 'antd/lib/input';
import './BdiaInput.less';
const { TextArea } = Input;
type ThemeStyle = 'dark' | 'white';
interface BdiaInputProps extends InputProps {
  size?: 'small' | 'middle' | 'large';
  login?: boolean;
  themeStyle?: ThemeStyle;
}
interface BdiaTextAreaProps extends TextAreaProps {
  themeStyle?: ThemeStyle;
}

type Mixin<T> = {
  [P in keyof (T & BdiaInputProps)]: (T & BdiaInputProps)[P];
};

function BaseInput<T>(baseKey: 'default' | 'Password' | 'Group') {
  return ({ size, className, login, themeStyle, ...props }: Mixin<T>) => {
    const Comp = baseKey !== 'default' ? Input[baseKey] : Input;
    return (
      <Comp
        className={classNames('bdia-input', `bdia-${size}-input`, `bdia-input-${themeStyle}`, className, {
          'bdia-login-input': login,
        })}
        {...props}
      />
    );
  };
}
function BdiaTextArea({ className, themeStyle, style, ...rest }: BdiaTextAreaProps) {
  return (
    <TextArea
      className={classNames('bdia-input', `bdia-input-${themeStyle}`, className)}
      style={{ resize: 'none', ...style }}
      {...rest}
    />
  );
}
const BdiaInput = (props: Mixin<{}>) => {
  return BaseInput('default')(props);
};

BdiaInput.Password = (props: Mixin<PasswordProps>) => {
  return BaseInput('Password')(props);
};

BdiaInput.Group = (props: Mixin<GroupProps>) => BaseInput('Group')(props);
BdiaInput.TextArea = (props: BdiaTextAreaProps) => BdiaTextArea(props);

BaseInput.defaultProps = {
  size: 'middle',
};
export default BdiaInput;
