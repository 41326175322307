import { createAsyncThunk } from '@reduxjs/toolkit';
import { EnumRemember } from '@/typings/enum';
import request from './request';

// 登录
export const login = createAsyncThunk(
  'common/login',
  async (params: { account: string; password: string; remember: EnumRemember }) => {
    return await request('user/login', {
      method: 'POST',
      body: params,
    });
  },
);

// 登出
export const logout = createAsyncThunk('common/logout', async () => {
  return await request('user/logout', {
    method: 'GET',
  });
});

// 获取用户信息
export const getUserinfo = createAsyncThunk('common/userinfo', async () => {
  return await request('user/info', {
    method: 'GET',
  });
});

// 修改锁屏时间
export const putLockTime = (params: { autoLockTime: number }) => {
  return request('user/locktime/update', {
    method: 'PUT',
    body: params,
  });
};

// 修改密码
export const putPassword = (params: { oldPassword: string; newPassword: string }) => {
  return request('user/password/change', {
    method: 'PUT',
    body: params,
  });
};

// 解锁屏幕
export const putUnlock = createAsyncThunk('common/unlock', async (params: { password: string }) => {
  return await request('user/unlock', {
    method: 'PUT',
    body: params,
  });
});
// 锁定屏幕
export const putLock = createAsyncThunk('common/lock', async () => {
  return await request('user/lock', {
    method: 'PUT',
  });
});
