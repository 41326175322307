import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './BdiaLoading.less';

const antIcon = <LoadingOutlined style={{ fontSize: '0.48rem', color: '#E2CC4B' }} spin />;

const BdiaLoading = () => {
  return (
    <div className="bdia-loading-mask">
      <Spin indicator={antIcon} size="large" />
    </div>
  );
};

export default BdiaLoading;
