import JSEncrypt from 'jsencrypt';
import { publickKey } from '@/config/project.dx';
import { store } from '@/store';
import { putLock } from '@/api/login';
import moment from 'moment';

let UIfontSize = 1;
export function Htmi_UI_Resize(): void {
  UIfontSize = (window.document.documentElement.clientHeight / 1080) * 100;
  document.getElementsByTagName('html')[0].style.fontSize = UIfontSize + 'px';
}

let resizeDisping = false;
export function Resize_Disp(): void {
  if (!resizeDisping) {
    resizeDisping = true;
    // 窗口发生变化自动适配
    window.onresize = function () {
      // const target = this;
      if (window.resizeFlag) {
        clearTimeout(window.resizeFlag);
      }

      window.resizeFlag = setTimeout(() => {
        Htmi_UI_Resize();
        // console.log("re");
        window.resizeFlag = null;
      }, 300);
    };
  }
}

export function InitUIScene(): void {
  // console.log("InitUIScene");
  if (!resizeDisping) {
    Htmi_UI_Resize();
    // Prototype_extend();
    Resize_Disp();
  }
}

export function remToPx(v: number) {
  return v * UIfontSize;
}

export function getBaseUrl() {
  return process.env.REACT_APP_BASE_URL;
}

export function RsaEncryption(pwd: string): string {
  const encryptor = new JSEncrypt();
  encryptor.setPublicKey(publickKey);
  return encryptor.encrypt(pwd) as string;
}

export function hexToRgba(hexColor: string, alpha = 0.5) {
  // 移除 # 号并提取颜色值
  const hex = hexColor.replace('#', '');

  // 将颜色值拆分成 R、G、B 三个部分
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // 转换为 RGBA 格式并添加透明度
  const rgba = `rgba(${r}, ${g}, ${b}, ${alpha})`;

  return rgba;
}
// HH:mm:ss格式的时间是否在范围内
export const timeInRange = (range: [string, string]) => {
  if (range[0] === range[1]) return true;
  const time = moment().format('HH:mm:ss');
  // 拼上日期，直接使用moment比较
  const date = moment().format('YYYY-MM-DD');
  const now = moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm:ss');
  const start = moment(`${date} ${range[0]}`, 'YYYY-MM-DD HH:mm:ss');
  const end = moment(`${date} ${range[1]}`, 'YYYY-MM-DD HH:mm:ss');
  if (start.isBefore(end)) {
    return now.isBetween(start, end);
  } else {
    start.subtract(1, 'd');
    if (now.isBetween(start, end)) {
      return true;
    } else {
      start.add(1, 'd');
      end.add(1, 'd');
      return now.isBetween(start, end);
    }
  }
};

export class WatchScreenLock {
  private static count: number;
  private static lockTimer: NodeJS.Timeout | null;

  public static set(time: number) {
    this.count = 0;
    if (this.lockTimer) {
      clearInterval(this.lockTimer);
    }
    if (time === 0) {
      return;
    }
    this.lockTimer = setInterval(() => {
      this.count++;
      if (this.count >= time) {
        store.dispatch(putLock());
        this.clear();
      }
    }, 1000);
    document.onmousemove = () => {
      this.reset();
    };
    return this.lockTimer;
  }

  public static clear() {
    this.count = 0;
    this.lockTimer && clearInterval(this.lockTimer);
  }

  public static reset() {
    this.count = 0;
  }

  private constructor() {
    WatchScreenLock.lockTimer = null;
    WatchScreenLock.count = 0;
  }
}
