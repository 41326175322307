/**
 * 自定义BrowserRouter 导出 history
 */
import React from 'react';
import { createBrowserHistory } from 'history';
import type { BrowserHistory } from 'history';
import { Router } from 'react-router';

export interface BrowserRouterProps {
  basename?: string;
  children?: React.ReactNode;
  window?: Window;
}

const browserHistory = createBrowserHistory({ window });

const BrowserRouter = ({ basename, children, window }: BrowserRouterProps) => {
  let historyRef = React.useRef<BrowserHistory>();
  if (historyRef.current === undefined) {
    historyRef.current = browserHistory;
  }

  let history = historyRef.current as BrowserHistory;
  let [state, setState] = React.useState({
    action: history?.action,
    location: history?.location,
  });

  React.useLayoutEffect(() => history.listen(setState), [history]);

  return React.createElement(
    Router,
    {
      basename: basename,
      location: state.location,
      navigationType: state.action,
      navigator: history,
    },
    children,
  );
};

export { browserHistory as bhistory, BrowserRouter };
