/**
 * 统一接口封装
 */
import qs from 'qs';
import { getBaseUrl } from '@/utils/general';
import Cookie from '@/utils/cookie';
import BdiaMessage from '@/components/BdiaMessage/BdiaMessage';
const version = 'v1';
/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
interface IRequestOption {
  method?: string;
  body?: any;
  headers?: any;
  credentials?: any;
}

export default function request(url: string, options: object) {
  let urlstr = version ? `${getBaseUrl()}/${version}/${url}` : `${getBaseUrl()}/${url}`;
  let token = '';
  if (Cookie.getCookie('token')) {
    token = Cookie.getCookie('token');
  }
  const defaultOptions = {
    credentials: 'include',
    headers: {
      Authorization: token,
    },
  };
  const newOptions: IRequestOption = { ...defaultOptions, ...options };
  if (newOptions.method === 'GET') {
    if (newOptions.body) {
      // 排序sort处理
      let sortParams = '';
      let sortlist = newOptions.body.sort;
      if (sortlist) {
        sortlist = sortlist.split(';');
        for (let s of sortlist) {
          sortParams += '&sort=' + s;
        }
      }
      const { sort, ...other } = newOptions.body;
      urlstr = `${urlstr}?${qs.stringify(other)}${sortParams}`;
      delete newOptions.body;
    }
  } else {
    if (newOptions.body instanceof FormData) {
      // newOptions.body is FormData
      newOptions.headers = {
        ...newOptions.headers,
      };
    } else {
      newOptions.headers = {
        'Content-Type': 'application/json; charset=utf-8',
        ...newOptions.headers,
      };
      newOptions.body = JSON.stringify(newOptions.body);
    }
  }

  return fetch(urlstr, newOptions)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      if (data.code === 0) {
        return Promise.resolve(data);
      } else if (data.code !== 500) {
        BdiaMessage.error({
          content: data.message,
        });
        if (data.code === 401 && window.location.pathname !== '/login') {
          // 重新登录
          window.location.href = '/login';
          Cookie.setCookie('token', '');
          return;
        }
        if (data.code === 403) {
          // Cookie.setCookie('token', '');
          return;
        }
      } else if (data.code === 500) {
        BdiaMessage.error({
          content: '服务器请求异常!',
        });
      }
      return Promise.resolve(data);
    });
}

export function download(api: string, data?: any, name?: string) {
  let url = `${getBaseUrl()}/${version}/${api}`;
  const cfg: any = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: Cookie.getCookie('token'),
    },
    method: 'GET',
  };
  if (data) {
    url += `?${qs.stringify(data)}`;
  }
  return fetch(url, cfg)
    .then((response) => {
      if (!response.ok) {
        throw Error('Network response was not OK');
      }
      return response;
    })
    .then((res) => {
      const fileName = res.headers.get('Content-disposition')?.split("attachment; filename*=utf-8''")[1];
      const type =
        res.headers.get('content-type')?.split(';')[0] ||
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      res.arrayBuffer().then((arraybuffer) => {
        let blob = new Blob([arraybuffer], {
          type,
        });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        if (fileName) {
          link.download = decodeURIComponent(fileName);
        }
        link.click();
        window.URL.revokeObjectURL(link.href);
      });
    });
}

export function downloadRequest(url: string, options: object) {
  let urlstr = version ? `${getBaseUrl()}/${version}/${url}` : `${getBaseUrl()}/${url}`;
  let token = '';
  if (Cookie.getCookie('token')) {
    token = Cookie.getCookie('token');
  }
  return `${urlstr}?Authorization=${encodeURIComponent(token)}`;
}
