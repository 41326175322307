import React from 'react';
import { ConfigProvider } from 'antd';
import Select from 'antd/lib/select';
import classnames from 'classnames';
import './BdiaSelect.less';
type SelectProps = React.ComponentProps<typeof Select>;
type ThemeStyle = 'dark' | 'white';
interface IBdiaSelectProps extends Omit<SelectProps, 'size'> {
  themeStyle?: ThemeStyle;
  selectClassName?: string;
  size?: 'large' | 'small' | 'mini';
}
const BdiaSelect = (props: IBdiaSelectProps) => {
  const { themeStyle, selectClassName, size, ...restProps } = props;

  return (
    <div
      className={classnames(
        'bdia-select',
        `bdia-select-${themeStyle}`,
        `${size ? `bdia-select-${size}` : ''}`,
        selectClassName,
      )}
    >
      <Select
        popupClassName="bdia-select-dropdown"
        suffixIcon={<span className={classnames('iconfont', 'icon-zhankaishouqi', 'select-arrow-icon')} />}
        notFoundContent={<EmptyContent />}
        {...restProps}
      />
    </div>
  );
};
BdiaSelect.defaultProps = {
  themeStyle: 'dark',
  size: 'large',
};
export default BdiaSelect;

function EmptyContent() {
  return (
    <div className="select-empty-box">
      <span className="iconfont icon-kongzhuangtai-01" />
      <span className="select-empty-text">无数据</span>
    </div>
  );
}
