import { createAsyncThunk } from '@reduxjs/toolkit';
import request, { download } from './request';
import { EnumGroupShortcut } from '@/typings/enum';
// 区域列表
export const getAreaList = createAsyncThunk('lamp/areaList', async () => {
  return await request('group/area', {
    method: 'GET',
  });
});
// 灯组列表
export const getBaseGroupList = createAsyncThunk('lamp/listStatus', async (params: { areaId: number }) => {
  return await request('group/list', {
    method: 'GET',
    body: params,
  });
});
// 获取照明空间层级
export const getFloorAreaList = createAsyncThunk('lamp/floorAreaList', async () => {
  return await request('space/light', {
    method: 'GET',
  });
});
// 节能统计
export const getGroupSaving = () => {
  return request('analysis/saving-stat', {
    method: 'GET',
  });
};

// 获取灯组详情
export const getGroupDetail = (params: { code: string }) => {
  return request('group/detail', {
    method: 'GET',
    body: params,
  });
};

// 信息卡中灯组的快捷操作
export const putGroupShortcut = (params: { code: string; brightness: number }) => {
  return request('control/group', {
    method: 'PUT',
    body: params,
  });
};
// 全部灯组的快捷操作
export const putAllShortcut = (params: { areaId: number; brightness: number }) => {
  return request('control/shortcut', {
    method: 'PUT',
    body: params,
  });
};

// 未处理列表
export const getUnhandleList = () => {
  return request('alarm/unhandled', {
    method: 'GET',
  });
};
// 标记处理
export const markHandled = (params: { id: number }) => {
  return request('alarm/mark-handled', {
    method: 'PUT',
    body: params,
  });
};
// 全部列表
export const getAllAlarmList = (params: AlarmListParams) => {
  return request('alarm/all', {
    method: 'GET',
    body: params,
  });
};
export interface AlarmListParams {
  startDate?: string;
  endDate?: string;
  page: number;
  size: number;
}
// 全部列表下载
export const downloadAlarmList = (data: { startDate?: string; endDate?: string }) => download('alarm/download', data);
// 获取转盘状态
export const getTurntableStatus = (params: { areaId: number }) => {
  return request('turntable/status', {
    method: 'GET',
    body: params,
  });
};

// 回路控制（单灯控制）
export const gatewayControl = (params: { code: string; brightness: number }) => {
  return request('control/gateway', {
    method: 'PUT',
    body: params,
  });
};
// 检测灯组是否删除
export const checkGroupIsDeleted = (params: { code: string }) => {
  return request('group/check-deleted', {
    method: 'GET',
    body: params,
  });
};
