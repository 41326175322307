import React, { useState, useEffect } from 'react';
import { ConfigProvider, DatePicker } from 'antd';
import classnames from 'classnames';
import type { Moment } from 'moment';
import 'moment/locale/zh-cn';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import './BdiaDatePicker.less';
import { RangePickerProps } from 'antd/lib/date-picker';
import moment from 'moment';

export type PickType = 'date' | 'week' | 'month' | 'quarter' | 'year';
export type RangeValue = [Moment | null, Moment | null] | null;
type SizeType = 'large' | 'middle' | 'small';
type ThemeStyle = 'dark' | 'white';
type IRangePickerProps = {
  themeStyle?: ThemeStyle;
  rpClassName?: string;
  pickType?: PickType;
  size?: SizeType;
  allowClear?: boolean;
  pickerRange?: RangeValue;
} & RangePickerProps;

const BdiaDateRangePicker = (props: IRangePickerProps) => {
  const { themeStyle, rpClassName, pickType, size, ...restProps } = props;

  return (
    <ConfigProvider locale={zh_CN}>
      <DatePicker.RangePicker
        className={classnames(
          'bdia-datepicker',
          `bdia-datepicker-${themeStyle}`,
          `${size ? `bdia-datepicker-${size}` : ''}`,
          rpClassName,
        )}
        dropdownClassName="bdia-picker-dropdown"
        picker={pickType}
        clearIcon={<span className={classnames('iconfont', 'icon-guanbi', 'icon-close')} />}
        {...restProps}
      />
    </ConfigProvider>
  );
};

BdiaDateRangePicker.defaultProps = {
  themeStyle: 'dark',
};

export default BdiaDateRangePicker;
